export default function initTabUnderline(_tab) {
  let $tab = document.querySelector( _tab );
  let tabOffsetLeft = $tab.offsetLeft;
  let $tabChild = $tab.children;
  let childLength = $tabChild.length;
  let tabWidth = parseInt($tab.clientWidth/childLength);

  let $slider = document.createElement('div');
  $slider.className = 'slider';

  let $bar = document.createElement('div');
  $bar.className = 'bar';
  $bar.style.width = 1 / childLength * 100 + '%';
  
  for(let val of $tabChild) {
    val.style.width = 1 / childLength * 100 + '%';
  }

  $slider.appendChild($bar);
  $tab.appendChild($slider);

  // $tab.addEventListener('mousemove', (e) => {
  //   let offsetX = e.target.offsetLeft - tabOffsetLeft;
  //   let index = Math.floor(offsetX/tabWidth);
    
  //   $bar.style.marginLeft = 1 / childLength * index * 100 + '%';
  // });

  return $bar;
}