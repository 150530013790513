export default function initHeaderFix() {
  let $header = document.querySelector('.tganime-header-fix'),
    defaultTop = $header.getBoundingClientRect().top,
    defaultHeight = $header.clientHeight;

  let $headerChild,
      headerHeightArr = [];

  let fn = {
    init: () => {
      $headerChild = fn.queryChild($header);

      for (let el of $headerChild) {
        headerHeightArr.push( el.clientHeight );
      }
    },
    scaleHeader: () => {
      $header.style.height = defaultHeight / 2 + 'px';
      
      for (let i = 0; i < $headerChild.length; i++) {
        let item = $headerChild[i];
        item.style.display = 'inline-block';
        item.style.transition = 'all .3s ease-in-out';
        item.style.transformOrigin = 'top left';
        // item.style.transform = 'translateY(-25%)';
        let elHeight = headerHeightArr[i],
          dis = 0;

        if (defaultHeight / 2 >= elHeight) {
          dis = defaultHeight / 4 - (defaultHeight / 2 - elHeight) / 2;
        } else {
          dis = elHeight / 2 - defaultHeight / 4;
        }

        item.style.transform = 'translateY(-' + dis + 'px)';
      }

      $header.classList.add('fix');
    },
    resetChild: () => {
      $header.style.height = 'auto';

      let $headerChild = fn.queryChild($header);

      for (let el of $headerChild) {
        el.style.transition = 'tranform .3s ease-in-out';
        el.style.transformOrigin = 'top left';
        el.style.transform = 'translateY(0)';
      }
    },
    queryChild: (_el) => {
      let $children = _el.children;

      if ($children.length == 1) {
        return fn.queryChild($children[0]);
      } else {
        return $children;
      }
    }
  }

  fn.init();

  if (window.scrollY != 0) {
    defaultTop = window.scrollY + defaultTop;
    fn.scaleHeader();
  }

  window.onscroll = function () {
    if (window.scrollY >= defaultTop) {
      if ($header.className.indexOf(' fix') < 0) {
        fn.scaleHeader();
      }
    } else {
      $header.classList.remove('fix');
      fn.resetChild();
    }
  }

}