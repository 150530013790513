import initTabUnderline from '../../tab/tganime-tab-underline/tganime-tab-underline'

export default function initSlideIn(_obj) {
  let $tab = document.querySelector(_obj.tab);
  let tabOffsetLeft = $tab.offsetLeft;
  let $tabChild = $tab.children;
  let _childLength = $tabChild.length;
  let auto = _obj.autoplay || false;
  let loop = _obj.loop || false;
  let index = 0;
  let step = 0;

  let touchOffsetX = 0;
  let touchOffsetY = 0;
  let canTouch = true;
  let touchTimer;

  let tabWidth = parseInt($tab.clientWidth / _childLength);

  for (var i = 0; i < $tabChild.length; i++) {
    (function (n) {
      $tabChild[n].addEventListener('mouseover', (e) => {
        index = n;
        step = -1 * index * 100 / _childLength;
        for (let val of $tabChild) {
          val.classList.remove('on');
        }

        $tabChild[index].classList.add('on');
        $container.style.transform = 'translate(' + step + '%' + ', 0)';
        $bar.style.marginLeft = 1 / _childLength * index * 100 + '%';
      });

      $tabChild[n].addEventListener('touchstart', (e) => {
        index = n;
        step = -1 * index * 100 / _childLength;
        for (let val of $tabChild) {
          val.classList.remove('on');
        }

        $tabChild[index].classList.add('on');
        $container.style.transform = 'translate(' + step + '%' + ', 0)';
        $bar.style.marginLeft = 1 / _childLength * index * 100 + '%';
      });
    })(i)
  }

  let $bar = initTabUnderline(_obj.tab);

  let $container = document.querySelector(_obj.slide);
  let $slideChild = $tab.children;

  $container.style.width = _childLength * 100 + '%';

  let $listChild = $container.children;
  for (let val of $listChild) {
    val.style.width = 1 / _childLength * 100 + '%';
  }

  // $tab.addEventListener('mousemove', (e) => {
  //   if (e.target.className.indexOf(_obj.tab.replace('.', '')) > -1) {
  //     return false;
  //   }
  //   let offsetX = e.target.offsetLeft - tabOffsetLeft > 0 ? e.target.offsetLeft - tabOffsetLeft : e.target.offsetLeft;
  //   index = Math.floor(offsetX / tabWidth);
  //   step = -1 * index * 100 / _childLength;
  //   for (let val of $tabChild) {
  //     val.classList.remove('on');
  //   }

  //   $tabChild[index].classList.add('on');
  //   $container.style.transform = 'translate(' + step + '%' + ', 0)';
  //   $bar.style.marginLeft = 1 / _childLength * index * 100 + '%';
  // });

  $container.addEventListener('touchstart', (e) => {
    touchOffsetX = e.touches[0].clientX;
    touchOffsetY = e.touches[0].clientY;
  }, false);

  $container.addEventListener('touchmove', (e) => {
    if (canTouch) {
      let distanceX = Math.abs(touchOffsetX - e.touches[0].clientX),
        distanceY = Math.abs(touchOffsetY - e.touches[0].clientY);

      if (distanceX > distanceY) {
        canTouch = false;
        touchOffsetX - e.touches[0].clientX > 0 ? foo.slideToNext() : foo.slideToPrev();
      }
    }
  }, false);

  $container.addEventListener('touchend', (e) => {
    canTouch = true;
  }, false);

  if (auto) {
    var slideinTimer = setInterval(() => {
      foo.slideToNext();
    }, auto);
  }

  var foo = {
    slideToNext: () => {
      index++;
      if (index == _childLength) {
        index = loop ? 0 : _childLength - 1;
      }

      for (let val of $tabChild) {
        val.classList.remove('on');
      }

      $tabChild[index].classList.add('on');

      step = -1 * index * 100 / _childLength;
      $tabChild[index].classList.add('on');
      $container.style.transform = 'translate(' + step + '%' + ', 0)';

      $bar.style.marginLeft = 1 / _childLength * index * 100 + '%';

    },
    slideToPrev: () => {
      index--;

      if (index < 0) {
        index = loop ? _childLength - 1 : 0;
      }

      for (let val of $tabChild) {
        val.classList.remove('on');
      }

      $tabChild[index].classList.add('on');

      step = -1 * index * 100 / _childLength;
      $tabChild[index].classList.add('on');
      $container.style.transform = 'translate(' + step + '%' + ', 0)';

      $bar.style.marginLeft = 1 / _childLength * index * 100 + '%';

    }
  }
}