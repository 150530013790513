export default function initOverlayFadein(_obj) {
  let $overlay = document.querySelector(_obj.overlay);
  let $trigger = document.querySelector(_obj.trigger);

  document.addEventListener('click', (e) => {
    if (e.target.className.indexOf(_obj.overlay) < 0 && $overlay.className.indexOf('show') > -1) {
      $overlay.classList.remove('fadein');

      let triggerHideTimer = setTimeout(() => {
        $overlay.classList.remove('show');
        clearTimeout(triggerHideTimer);
      }, 100);
    } else {
      if (e.target.className.indexOf(_obj.trigger.replace('.', '')) > -1 && $overlay.className.indexOf('show') < 0) {
        $overlay.classList.add('show');

        let triggerShowTimer = setTimeout(() => {
          $overlay.classList.add('fadein');
          clearTimeout(triggerShowTimer);
        }, 100);
      }
    }
  })
}