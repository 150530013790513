import {getCssLink, getScript} from '../../util/tganime-util'

export default function initSlideBook(_obj) {
  let interleaveOffset = 0.5;
  let swiperOptions = {
    loop: true,
    speed: 600,
    grabCursor: true,
    watchSlidesProgress: true,
    mousewheelControl: true,
    keyboardControl: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    on: {
      progress: function () {
        let swiper = this;
        for (let i = 0; i < swiper.slides.length; i++) {
          let slideProgress = swiper.slides[i].progress;
          let innerOffset = swiper.width * interleaveOffset;
          let innerTranslate = slideProgress * innerOffset;
          swiper.slides[i].querySelector(".tganime-slide-inner").style.transform = "translate3d(" + innerTranslate + "px, 0, 0)";
        }
      },
      touchStart: function () {
        let swiper = this;
        for (let i = 0; i < swiper.slides.length; i++) {
          swiper.slides[i].style.transition = "";
        }
      },
      setTransition: function (speed) {
        let swiper = this;
        for (let i = 0; i < swiper.slides.length; i++) {
          swiper.slides[i].style.transition = speed + "ms";
          swiper.slides[i].querySelector(".tganime-slide-inner").style.transition = speed + "ms";
        }
      }
    }
  };

  getCssLink('https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.0.7/css/swiper.min.css', function () {
    getScript({
      url: 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.0.7/js/swiper.min.js',
      callback: function () {
        let opt = Object.assign(swiperOptions, _obj);
        let swiper = new Swiper(_obj.container, opt);
      }
    });
  });
}