export default function initTotop(_obj) {
  let $topBtn = document.querySelector(_obj.container);
  let timer;

  $topBtn.addEventListener('click', (e) => {
    e.stopPropagation();
    util.scrollToTop();
  })

  document.addEventListener('touchstart', (e) => {
    clearInterval(timer);
  });

  document.addEventListener('mousewheel', (e) => {
    clearInterval(timer);
  });

  let util = {
    scrollToTop: () => {
      let isTop = true,
        cancelScroll = false;

      if (cancelScroll == false) {
        timer = setInterval(function () {
          let osTop = document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 3);
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
          isTop = true;
          if (osTop == 0) {
            clearInterval(timer);
          }
        }, 50);
      } else {
        clearInterval(timer);
        cancelScroll = true;
      }
    }
  }
}