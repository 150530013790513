  let getCssLink = (_url, _callback) => {
    var callback = _callback || function () {};
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = _url;

    link.onload = link.onreadystatechange = function () {
      callback();
    };
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  let getScript = (opt) => {
    let options = {
      url: opt.url || '',
      params: {} || opt.params,
      loadRemove: true,
      loading: opt.loading,
      callback: opt.callback || function () {}
    };

    if (options.loading) {
      common.ajaxLoading('show');
    }
    let script = document.createElement('script');
    script.async = "async";
    for (let q in options.params) {
      script[q] = options.params[q];
    }
    script.src = options.url;
    script.onload = script.onreadystatechange = function () {
      if (!this.readyState || this.readyState === "loaded" || this.readyState === "complete") {
        options.callback();
      }
    };
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  export {
    getCssLink,
    getScript
  }