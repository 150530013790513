import initTabUnderline from './tab/tganime-tab-underline/tganime-tab-underline'
import initSlideIn from './slide/tganime-slide-slidein/tganime-slide-slidein'
import initSlideBook from './overlay/tganime-slide-book/tganime-slide-book'
import initHeaderFix from './parenting/tganime-header-fix/tganime-header-fix'
import initOverlayFadein from './overlay/tganime-overlay-fadein/tganime-overlay-fadein'
import initTotop from './dollyzoom/tganime-totop/tganime-totop'

var TGAnime = {
  init: (_class, _obj) => {
    switch (_class) {
      case 'tganime-tab-underline':
        initTabUnderline();
        break;
      case 'tganime-slide-slidein':
        initSlideIn(_obj);
        break;
      case 'tganime-slide-book':
        initSlideBook(_obj);
        break;
      case 'tganime-header-fix':
        initHeaderFix();
        break;
      case 'tganime-overlay-fadein':
        initOverlayFadein(_obj);
        break;
        case 'tganime-totop':
        initTotop(_obj);
        break;
      default:
        return;
    }
  }
}

window.TGAnime = TGAnime;

export {
  TGAnime
};